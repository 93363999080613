import React, { Component } from 'react';

import { BrowserRouter as Router, Link, Route, Redirect, Switch, withRouter } from "react-router-dom";

import {DefaultDisplayComponent} from './DefaultDisplayComponent';

import {ApiPageComponent} from './ApiPageComponent';

import {EcommerceApiComponent} from './EcommerceApiComponent';

import {GenericApiComponent} from './GenericApiComponent';

import {GenericEcommerceApiComponent} from './GenericEcommerceApiComponent';

import {WooCommerceApiComponent} from './WooCommerceApiComponent';

import {HighligthComponent} from './HighligthComponent';

export class MainDisplayComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }
   
    render = () =>
        <div className="app-main__outer">
            <div className="app-main__inner">
                <Switch>
                    <Route path="/" exact={ true } component={ DefaultDisplayComponent } />
                    <Route path="/airtime"  render={ (props) => <GenericApiComponent product="airtime" {...props} key={props.location.pathname}/> } />
                    
                    <Route path="/money" exact={ true } render={ (props) => <GenericApiComponent product="money" {...props} key={props.location.pathname} /> } />
                    <Route path="/bill" exact={ true } render={ (props) => <GenericApiComponent product="bill" {...props} key={props.location.pathname} /> } />
                    <Route path="/account" exact={ true } render={ (props) => <GenericApiComponent product="account" {...props} key={props.location.pathname} /> } />
                    <Route path="/messaging" exact={ true } render={ (props) => <GenericApiComponent product="messaging" {...props} key={props.location.pathname} /> } />
                    <Route path="/intl" exact={ true } render={ (props) => <GenericApiComponent product="intl" {...props} key={props.location.pathname} /> } />

                    <Route path="/ecommerce" exact={ true }  render={ (props) => <GenericEcommerceApiComponent {...props} key={props.location.pathname}/> } />
                    <Route path="/ecommerce/website" exact={ true }  render={ (props) => <EcommerceApiComponent {...props} key={props.location.pathname}/> } />
                    <Route path="/ecommerce/woocommerce" exact={ true } render={ (props) => <WooCommerceApiComponent {...props} key={props.location.pathname} /> } />

                    <Route path="/documentation/:slug1/:slug2?/:slug3?/:slug4?/:slug5?/" exact={ true } render={ (props) => <ApiPageComponent {...props} key={props.location.pathname} /> } />

                    <Redirect to="/" />
                </Switch>
            </div>
        </div>
}
