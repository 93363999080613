import React, { Component } from 'react';

export class HeaderComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logoStyles: {
                width: '8rem',
                marginTop: '-0.5rem',
                marginLeft: '4rem'
            }
        };
    }

    render = () =>
        <div className="app-header header-shadow bg-night-sky header-text-light">
            <div className="app-header__logo">
                <div className="logo-src">
                    <a href="/doc.html">
                        <img src="/assets/images/logo_mono.png" style={this.state.logoStyles}/>
                    </a>
                </div>
                <div className="header__pane ml-auto">
                    <div>
                        <button type="button" className="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                                <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                                </span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="app-header__mobile-menu">
                <div>
                    <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                            <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                            </span>
                    </button>
                </div>
            </div>
            <div className="app-header__menu">
                    <span>
                        <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                            <span className="btn-icon-wrapper">
                                <i className="fa fa-ellipsis-v fa-w-6"></i>
                            </span>
                        </button>
                    </span>
            </div>
            <div className="app-header__content">
                <div className="app-header-right">
                    <ul className="header-megamenu nav">
                        <li className="nav-item">
                            <a href="/doc.html" className="nav-link">
                                <i className="nav-link-icon pe-7s-news-paper"> </i>
                                Documentation
                            </a>
                        </li>

                        <li className="nav-item">
                            <a href="/index.html" className="nav-link">
                                <i className="nav-link-icon pe-7s-culture"> </i>
                                Home
                            </a>
                        </li>

                        {/*
                        <li className="dropdown nav-item">
                            <a aria-haspopup="true" data-toggle="dropdown" className="nav-link" aria-expanded="false">
                                <i className="nav-link-icon pe-7s-key"> </i> Login
                            </a>
                        </li>
                         */}
                    </ul>
                </div>
            </div>
        </div>
    }
